import type {
  IEmailForm,
  IRegisterForm,
  IUser,
  IUsernameForm,
} from "~/models/IUser";
import type { IVerifyToken } from "~/models/IVerifyToken";
import { UserClient } from "~/api/user";

type Nullable<T> = { [K in keyof T]: T[K] | null };

export const useUserStore = defineStore("user", () => {
  const pageContext = usePageContext();
  const user = ref(pageContext.user);
  const redirectAfterLogin = ref<string | null>(null);
  const importLocalEntries = ref<boolean>(true);

  const { persist } = useToast();
  const loggedIn = computed(() => user.value !== null);

  const api = useApiClient(UserClient);

  async function refreshUser() {
    try {
      user.value = await api.refreshUser();
    } catch (_) {
      const wasLoggedIn = loggedIn.value;
      user.value = null;

      if (wasLoggedIn) {
        persist(
          "Ihre Session ist leider abgelaufen. Melden Sie sich neu an, um alle Funktionen nutzen zu können.",
          { type: "info" }
        );
        window.location.href = "/intro";
      }
    }
  }

  async function login(form: {
    email: string;
    password: string;
    remember: boolean;
  }) {
    await api.login(form);
    persist("Du hast dich erfolgreich angemeldet.", {
      type: "success",
    });
    if (redirectAfterLogin.value) {
      window.location.replace(redirectAfterLogin.value);
      redirectAfterLogin.value = null;
    } else {
      window.location.reload();
    }
  }

  /**
   * Do user logout, reloading the page to reset all stores, etc.
   */
  async function logout() {
    await api.logout();
    persist("Du hast dich erfolgreich abgemeldet.", {
      type: "success",
    });
    window.location.href = "/intro";
  }

  async function register(form: IRegisterForm) {
    await api.register(form);
  }

  async function validateEmail(form: {
    email: string;
    code: string;
  }): Promise<string> {
    return await api.validateEmail(form);
  }

  async function resendEmailCode(form: { email: string }) {
    await api.resendEmailCode(form);
  }

  async function resetPassword(payload: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
  }) {
    await api.resetPassword(payload);
  }

  async function verify({
    id,
    hash,
    expires,
    signature,
  }: Nullable<IVerifyToken>) {
    if (!(id && hash && expires && signature)) {
      throw new Error("some part of the token is missing");
    }
    await api.verify({ id, hash, expires, signature });
  }

  async function sendMail(email: string) {
    await api.sendMail(email);
  }

  async function forgotPassword(email: string) {
    await api.forgotPassword(email);
  }

  async function updatePassword(payload: {
    current_password: string;
    password: string;
    password_confirmation: string;
  }) {
    await api.updatePassword(payload);
  }

  function confirmPassword(password: string) {
    return api.confirmPassword({ password });
  }

  async function deleteAccount() {
    await api.deleteAccount();
  }

  async function updateUser(updated: Partial<IUser>) {
    await api.updateUser(updated);
    await refreshUser();
  }

  async function updateUsername(updated: IUsernameForm) {
    await api.updateUsername(updated);
    await refreshUser();
  }

  async function updateNewsletterAccepted(newsletter_accepted: boolean) {
    await api.updateUser({ newsletter_accepted });
    user.value!.newsletter_accepted = newsletter_accepted;
  }

  async function updateEmail(data: IEmailForm) {
    await api.changeEmail(data);
  }

  async function updateProfileImage(file: File) {
    await api.updateUserProfileImage(file);
    await refreshUser();
  }

  async function deleteProfileImage() {
    await api.deleteUserProfileImage();
    if (user.value) {
      user.value = { ...user.value, profile_image: undefined };
    }
  }

  return {
    user,
    loggedIn,
    redirectAfterLogin,
    importLocalEntries,
    refreshUser,
    login,
    logout,
    register,
    validateEmail,
    resendEmailCode,
    resetPassword,
    verify,
    sendMail,
    forgotPassword,
    updatePassword,
    confirmPassword,
    deleteAccount,
    updateUser,
    updateUsername,
    updateNewsletterAccepted,
    updateEmail,
    updateProfileImage,
    deleteProfileImage,
  };
});
